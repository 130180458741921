import { useEffect, useState } from "react";
import styles from "./where-we-fly-map.module.scss";
import {
  ComposableMap,
  Geographies,
  Geography,
  Marker,
  ZoomableGroup,
} from "react-simple-maps";
import TGIcon from "../../../../shared/tg-icon";
import { NavCardData } from "../../models/models";
import { ThailandMap } from "./thailandMap";

interface WhereWeFlyMapProps {
  onHoverCountry: string;
  markerInfo: NavCardData[];
}

const WhereWeFlyMap: React.FC<WhereWeFlyMapProps> = ({
  onHoverCountry,
  markerInfo,
}) => {
  const [hoveredCountry, setHoveredCountry] = useState<string | null>(null);
  const [zoomLevel, setZoomLevel] = useState<number>(1);
  const [mapCenterCords, setMapCenterCords] = useState<[number, number]>([
    0, 0,
  ]);
  //This is specific to map and not changeable, so no need to come from TS
  const getMapPointerCords = (regionCode: string): [number, number] => {
    switch (regionCode) {
      case "ASA":
        return [1540, 1470];
        break;
      case "MEA":
        return [1480, 1470];
        break;
      case "EU":
        return [1520, 1505];
        break;
      case "THA":
        return [1537, 1457];
        break;
      default:
        return [1540, 1475];
    }
  };

  useEffect(() => {
    handleSetHoveredCountry(onHoverCountry);
  }, [onHoverCountry]);

  const handleSetHoveredCountry = (param: string) => {
    setHoveredCountry(param);
    if (param === "THA") {
      setZoomLevel(6);
      setMapCenterCords([100, 170]);
    } else {
      setZoomLevel(1);
      setMapCenterCords([0, 0]);
    }
  };

  return (
    <>
      <div className={styles.map}>
        {hoveredCountry !== "THA" ? (
          <ComposableMap>
            <ZoomableGroup zoom={zoomLevel} center={mapCenterCords}>
              <Geographies geography="/json/whereWeFlyMapData.json">
                {({ geographies }: any) =>
                  geographies.map((geo: any) => (
                    <Geography
                      key={geo.id}
                      geography={geo}
                      style={
                        hoveredCountry === geo.id
                          ? {
                              default: { fill: "#C6AF7C", stroke: "#b8a273" },
                              hover: { fill: "#C6AF7C", stroke: "#b8a273" },
                              pressed: { fill: "#C6AF7C", stroke: "#b8a273" },
                            }
                          : {
                              default: { fill: "#F2F2FA", stroke: "#F2F2FA" },
                              hover: { fill: "#F2F2FA" },
                              pressed: { fill: "#F2F2FA" },
                            }
                      }
                    />
                  ))
                }
              </Geographies>
              {markerInfo.map(
                (marker: NavCardData, index: number) =>
                  marker?.regionCode && (
                    <Marker
                      key={index}
                      coordinates={getMapPointerCords(marker.regionCode)}
                    >
                      <a
                        key={index}
                        title={"map-marker-" + marker.regionCode}
                        href={marker.redirectionUrl}
                      >
                        {marker.regionCode !== "THA" &&
                          (hoveredCountry === marker.regionCode ||
                            hoveredCountry === null ||
                            hoveredCountry === "") && (
                            <TGIcon
                              icon={"map-marker"}
                              fillColor="#2D1656"
                              size="34"
                              strokeColor="#2D1656"
                            ></TGIcon>
                          )}
                        {hoveredCountry === "THA" &&
                          marker.regionCode === "THA" && (
                            <TGIcon
                              icon={"map-marker"}
                              fillColor="#2D1656"
                              size="10"
                              strokeColor="#2D1656"
                            ></TGIcon>
                          )}
                      </a>
                    </Marker>
                  )
              )}
            </ZoomableGroup>
          </ComposableMap>
        ) : (
          <ThailandMap />
        )}
      </div>
    </>
  );
};

export default WhereWeFlyMap;
